import { type LDSingleKindContext, initialize } from 'launchdarkly-js-client-sdk';

const LOCAL_STORAGE_AUTH_KEY = 'ember_simple_auth-session';

interface TokenData {
	token: string;
	email: string;
	exp: number;
	firstName: string;
	lastName: string;
	parentUserId: string | null;
	permissions: { l: number; p: number }[];
	authenticator: string;
	sub: number;
}

export const getTokenData = () => {
	const authStorageData = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) ?? '{}';
	const authStorage = JSON.parse(authStorageData) as { authenticated?: TokenData };
	return authStorage?.authenticated?.token && authStorage?.authenticated?.sub ? authStorage?.authenticated : null;
};

export const checkTokenExpired = () => {
	const token = getTokenData();
	const currentTime = Date.now() / 1000;
	return token && token.exp < currentTime;
};

export const initializeLaunchDarkly = async (token: TokenData) => {
	const user: LDSingleKindContext = {
		kind: 'user',
		key: `${token.sub}`,
		email: token.email,
		name: '',
		custom: {
			customerName: `${token.firstName} ${token.lastName}`.trim(),
		},
	};
	// TODO Refactor to use dotenv
	const ldClientId = process.env.NODE_ENV === 'production' ? '614022e9694e8d262b59f82f' : '62695639cb0596159e560770';
	const client = initialize(ldClientId, user);
	await client.waitUntilReady();
	return client;
};
